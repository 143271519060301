.container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 20px;
  margin-right: 12.5px;
  background-color: #3d4e8d;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
