@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

:root {
  --UNLP_COLOR: #02545e;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

div.react-chatbot-kit-chat-input-container > form > input {
  font-size: 14px;
  font-family: 'Roboto', Helvetica, sans-serif;
}

.react-chatbot-kit-chat-btn-send {
  background-color: var(--UNLP_COLOR);
}

.react-chatbot-kit-chat-inner-container {
  position: relative;
  overflow-x: hidden;
}

.react-chatbot-kit-chat-container,
.first-button-container {
  font-family: 'Roboto', Helvetica, sans-serif;
  position: fixed;
  margin-right: 1em;
  bottom: 65px;
  right: 0;
  z-index: 999;
}

.react-chatbot-kit-chat-container {
  width: 300px;
  bottom: 30px;
}

.react-chatbot-kit-chat-message-container {
  overflow-x: hidden;
}

.react-chatbot-kit-chat-bot-message {
  word-wrap: break-word;
}

.react-chatbot-kit-chat-bot-message > span {
  font-size: 14px;
  line-height: 1em !important;
  margin-bottom: 5px;
}

.react-chatbot-kit-chat-bot-message > span > p {
  line-height: 1em !important;
  margin-bottom: 0 !important;
  font-size: 14px;
}

.react-chatbot-kit-chat-bot-message > span > p > a {
  text-decoration: underline;
  color: #fff;
  transition: all 0.1s;
}

.react-chatbot-kit-chat-bot-message > span > p > a:hover {
  color: lightblue;
}
.react-chatbot-kit-chat-bot-message > span > p > a:visited {
  color: rgb(125, 202, 228);
}

.react-chatbot-kit-user-chat-message {
  font-size: 14px;
}

.first-button-container {
  border-radius: 5px;
  padding: 8px;
  background: transparent;
  font-size: 10px;
  background-color: var(--UNLP_COLOR);
  color: white;
  cursor: pointer;
  outline: none;
  border: none;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  gap: 8px;
}

.first-button-container:hover {
  opacity: 0.9;
  transform: scale(1.1);
}

@media (min-width: 768px) {
  .first-button-container {
    font-size: 14px;
    padding: 1em;
  }
}
