.button_container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 3px;
}

.button {
  font-family: 'Roboto', Helvetica, sans-serif;
  padding: 8px;
  border-radius: 25px;
  background: transparent;
  outline: none;
  border: 1px solid var(--UNLP_COLOR);
  font-weight: 400 !important;
  text-align: center;
  transition: all 0.2s;
  font-size: 12px;
}

.button:hover {
  color: white;
  background-color: var(--UNLP_COLOR);
  border-color: white;
  cursor: pointer;
  opacity: 0.8;
}
